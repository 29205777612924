$displays: (
	'none': 'none',
	'inline': 'inline',
	'inline-block': 'inline-block',
	'block': 'block',
	'table': 'table',
	'table-cell': 'table-cell',
	'table-row': 'table-row',
	'flex': 'flex',
	'grid': 'grid',
	'inline-flex': 'inline-flex',
);
/***
hub-d-none
hub-d-inline
hub-d-inline-block
hub-d-block
hub-d-table
hub-d-table-cell
hub-d-table-row
hub-d-flex
hub-d-grid
hub-d-inline-flex
 */
@mixin setDisplays($className) {
	@each $name, $property in $displays {
		.#{$prefix}-#{$className}-#{$name} {
			display: #{$property} !important;
		}
	}
}

@include setDisplays('d');

.#{$prefix}-opacity-0 {
	opacity: 0;
}

.#{$prefix}-opacity-50 {
	opacity: 0.5;
}

.#{$prefix}-opacity-100 {
	opacity: 1;
}
