/**
* Hub Main colors
* for more info please see : https://www.figma.com/file/8oSMIew2FSiOE2Fhj78JGD/OL-Design-System?node-id=14%3A73
*/

// main colors (useful for override boostrap ones)
$primary-hub: #e96b0f;
$secondary-hub: #5169e3;
$success-hub: #55a816;
$danger-hub: #e13549;
$light-hub: #f7f7f7;
$dark-hub: #333;

// CTA
$muted-cta-hub: #d14a14;
$shade-cta-hub: #f8d3b7;
$disabled-selected-cta-hub: #fdf3ec;

// text colors
$disabled-text-hub: #9e9e9e;
$light-text-hub: #515151;
$message-text-hub: #7f7f7f;
$premium-text-hub: #E7A20B;

// navigation ( ignore main-bright = $secondary-hub)
$dark-navigation-hub: #1c2451;
$muted-navigation-hub: #3c4da7;
$active-navigation-hub: #dce0f9;
$main-shade-hub: #e5e8fb;

// Mappers
$active-mapper-hub: #c5defb;
$defailt-mapper-hub: #2d9cdb;
$selected-mapper-hub: #f2f7fe;

// neutral
$bg-sidenav-neutral-hub: #f4f6fd;
$bg-flyout-neutral-hub: #f3f4fa;
$gray-line-divider-frame-neutral-hub: #e9ebee;
$gray-outline-disabled-grey-neutral-hub: #c1c6d2;
$grey3-methods-incomplete: #9ca2b0;
$card-icons-neutral-hub: #686c76;
$border-dash-grey: #cbcccd;
$border-neutral: #f3f3f3;
$grey-test-cases: #E0E3E8;

// Generators
$loading-generator-hub: #9aed67;

// section colors
$assets-section-hub: #47beb0;
$methods-section-hub: #5aa1f4;
$projects-section-hub: #f18282;
$services-section-hub: #795af4;
$modules-section-hub: #fcb414;
$contracts-section-hub: #0eb9df;
$connectors-section-hub: #2a6ab2;
$generators-section-hub: #197e85;

// rest swatches
$delete-swatch-hub: #fa3e3e;
$get-swatch-hub: #5eb0fe;
$patch-swatch-hub: #4de2c2;
$post-swatch-hub: #48cc90;
$put-swatch-hub: #fda030;

// main-header-height
$main-header-height: 3rem;

// main-sidebar-width
$main-sidebar-width: 6rem;

// set-static-value block border
$set-static-value-color: #a932e0;

// monaco-colors
$tamarillo: #a31515;

// custom-expressions-edited-rows
$zumthor: #edf5ff;

// Project prefix
$prefix: 'hub' !default;

// Project spacer size
$spacer: 1rem !default;

// Project space definitions
$spacers-list: (
	0: 0,
	'0-25': ($spacer * .25),
	'0-5': ($spacer * .5),
	'0-625': ($spacer * .625),
	'0-75': ($spacer * .75),
	'1': $spacer,
	'1-25': ($spacer * 1.25),
	'1-5': ($spacer * 1.5),
	'2': ($spacer * 2),
	'2-25': ($spacer * 2.25),
	'2-5': ($spacer * 2.5),
	'3': ($spacer * 3),
	'3-5': ($spacer * 3.5),
	'3-75': ($spacer * 3.75),
	'4': ($spacer * 4),
	'10': ($spacer * 10)
);

// Project text colors
$text-colors: (
	'transparent': "transparent",
	'primary': $primary-hub,
	'secondary': $secondary-hub,
	'success': $success-hub,
	'danger': $danger-hub,
	'light': $light-hub,
	'lighter': $light-text-hub,
	'dark': $dark-hub,
	'disabled': $disabled-text-hub,
	'message': $message-text-hub,
	'premium': $premium-text-hub,
	'muted': $muted-navigation-hub,
	'date': $grey3-methods-incomplete,
	'white': #fff,
	'neon-green': #06EC1D,
);

/* Deprecated */
$card-neutral-background: #fafafa;
$banner-generator-hub: #59637a;
$hover-mapper-hub: #e6f1fd;
$shade-navigation-hub: #dfe4f7; //sub-nav selected
