.custom-badge {
	font-size: 0.8rem;
	color: #fff;
	border-radius: 0.3rem;
	border: 0 !important;
	padding: 0.1rem 0.6rem !important;
}

.status-badge {
	font-size: 0.8rem;
	color: #000;
	background-color: #E9EBEE;
	border-radius: 0.6rem;
	border: 0 !important;
	padding: 0.1rem 0.6rem !important;
}

.status-badge-success {
	background-color: #DDEED0;
}

.status-badge-fail {
	background-color: #FFE7EA;
}

.status-badge-running {
	background-color: #c5cbf1;
}
