.hub-icons,
[class*='icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Tamicons', serif !important;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: never;
	text-transform: none;
}

.icon-utility::before {
	content: "\e9fc";
}

.icon-xsd-file::before {
	content: "\e9fd";
}

.icon-reset-password::before {
	content: "\e9f7";
}

.icon-customize::before {
	content: "\e9f8";
}

.icon-team-members::before {
	content: "\e9f9";
}

.icon-complete::before {
	content: "\e9fa";
}

.icon-select-from-field::before {
	content: "\e9fb";
}

.icon-parallel::before {
	content: "\e9f5";
}

.icon-emulation::before {
	content: "\e9f6";
}

.icon-fetchers::before {
	content: "\e9f1";
}

.icon-preprocessor::before {
	content: "\e9f2";
}

.icon-attributes::before {
	content: "\e9f3";
}

.icon-no-attributes::before {
	content: "\e9f4";
}

.icon-agents::before {
	content: "\e9ec";
}

.icon-consumer-properties::before {
	content: "\e9ed";
}

.icon-export::before {
	content: "\e9ee";
}

.icon-extend::before {
	content: "\e9ef";
}

.icon-home::before {
	content: "\e9f0";
}

.icon-timer::before {
	content: "\e9ea";
}

.icon-blanks::before {
	content: "\e9eb";
}

.icon-change-parent::before {
	content: "\e9e9";
}

.icon-reset-new::before {
	content: "\e9e8";
}

.icon-xml-file::before {
	content: "\e9e7";
}

.icon-keys-created::before {
	content: "\e9e4";
}

.icon-keys::before {
	content: "\e9e5";
}

.icon-test-plan::before {
	content: "\e9e6";
}

.icon-cloud::before {
	content: "\e9e3";
}

.icon-i-info::before {
	content: "\e9e2";
}

.icon-static-value-circle::before {
	content: "\e9cb";
}

.icon-file-upload::before {
	content: "\e9ce";
}

.icon-folder-upload::before {
	content: "\e9cf";
}

.icon-grid-view::before {
	content: "\e9d0";
}

.icon-list-view::before {
	content: "\e9d1";
}

.icon-new-folder::before {
	content: "\e9d2";
}

.icon-share-to-user::before {
	content: "\e9d3";
}

.icon-shared-to::before {
	content: "\e9d4";
}

.icon-ai::before {
	content: "\e9d5";
}

.icon-expand-width::before {
	content: "\e9d6";
}

.icon-notification-announcement::before {
	content: "\e9d7";
}

.icon-notification-database::before {
	content: "\e9d8";
}

.icon-notification-runtime::before {
	content: "\e9d9";
}

.icon-notification-system::before {
	content: "\e9da";
}

.icon-test-case::before {
	content: "\e9db";
}

.icon-explore-file::before {
	content: "\e9dc";
}

.icon-history::before {
	content: "\e9dd";
}

.icon-license::before {
	content: "\e9de";
}

.icon-file-type::before {
	content: "\e9df";
}

.icon-folder-filled::before {
	content: "\e9e0";
}

.icon-screens::before {
	content: "\e9e1";
}

.icon-ai-generate::before {
	content: "\e900";
}

.icon-new-tab::before {
	content: "\e901";
}

.icon-premium-nofilled::before {
	content: "\e902";
}

.icon-premium::before {
	content: "\e903";
}

.icon-regenerate::before {
	content: "\e904";
}

.icon-dots::before {
	content: "\e905";
}

.icon-action::before {
	content: "\e906";
}

.icon-add-field::before {
	content: "\e907";
}

.icon-add-link::before {
	content: "\e908";
}

.icon-add-module-from-list::before {
	content: "\e909";
}

.icon-align-center::before {
	content: "\e90a";
}

.icon-align-left::before {
	content: "\e90b";
}

.icon-align-right::before {
	content: "\e90c";
}

.icon-api-1::before {
	content: "\e90d";
}

.icon-api::before {
	content: "\e90e";
}

.icon-arrow-circle-left::before {
	content: "\e90f";
}

.icon-arrow-circle-right::before {
	content: "\e910";
}

.icon-arrow-down::before {
	content: "\e911";
}

.icon-arrow-left::before {
	content: "\e912";
}

.icon-arrow-right::before {
	content: "\e913";
}

.icon-arrow-up::before {
	content: "\e914";
}

.icon-assets::before {
	content: "\e915";
}

.icon-attachments::before {
	content: "\e916";
}

.icon-back-to::before {
	content: "\e917";
}

.icon-bold::before {
	content: "\e918";
}

.icon-bookmark-empty::before {
	content: "\e919";
}

.icon-bookmark-filled::before {
	content: "\e91a";
}

.icon-boolean::before {
	content: "\e91b";
}

.icon-broken-link::before {
	content: "\e91c";
}

.icon-browse::before {
	content: "\e91d";
}

.icon-cached::before {
	content: "\e91e";
}

.icon-calendar::before {
	content: "\e91f";
}

.icon-caret-down::before {
	content: "\e920";
}

.icon-caret-left::before {
	content: "\e921";
}

.icon-caret-right::before {
	content: "\e922";
}

.icon-caret-up::before {
	content: "\e923";
}

.icon-case::before {
	content: "\e924";
}

.icon-catalog::before {
	content: "\e925";
}

.icon-change-connection::before {
	content: "\e926";
}

.icon-change-password::before {
	content: "\e927";
}

.icon-check::before {
	content: "\e928";
}

.icon-chevron-down::before {
	content: "\e929";
}

.icon-chevron-left::before {
	content: "\e92a";
}

.icon-chevron-right::before {
	content: "\e92b";
}

.icon-chevron-up::before {
	content: "\e92c";
}

.icon-cli::before {
	content: "\e92d";
}

.icon-close-circle::before {
	content: "\e92e";
}

.icon-collapse::before {
	content: "\e92f";
}

.icon-collapse2::before {
	content: "\e930";
}

.icon-color-id::before {
	content: "\e931";
}

.icon-compass-2::before {
	content: "\e932";
}

.icon-compass::before {
	content: "\e933";
}

.icon-condition-1::before {
	content: "\e934";
}

.icon-condition::before {
	content: "\e935";
}

.icon-config-cache-keys::before {
	content: "\e936";
}

.icon-config-cache::before {
	content: "\e937";
}

.icon-connection-profile::before {
	content: "\e938";
}

.icon-connections::before {
	content: "\e939";
}

.icon-connector::before {
	content: "\e93a";
}

.icon-contracts::before {
	content: "\e93b";
}

.icon-copy::before {
	content: "\e93c";
}

.icon-create-module::before {
	content: "\e93d";
}

.icon-cursor-position::before {
	content: "\e93e";
}

.icon-dashboard::before {
	content: "\e93f";
}

.icon-database::before {
	content: "\e940";
}

.icon-date::before {
	content: "\e941";
}

.icon-default-case::before {
	content: "\e942";
}

.icon-delete-action::before {
	content: "\e943";
}

.icon-delete::before {
	content: "\e944";
}

.icon-deployment-scripts::before {
	content: "\e945";
}

.icon-double-integer::before {
	content: "\e946";
}

.icon-download::before {
	content: "\e947";
}

.icon-drag::before {
	content: "\e948";
}

.icon-edit-action::before {
	content: "\e949";
}

.icon-edit::before {
	content: "\e94a";
}

.icon-editable::before {
	content: "\e94b";
}

.icon-error-output::before {
	content: "\e94c";
}

.icon-event-case::before {
	content: "\e94d";
}

.icon-excel::before {
	content: "\e94e";
}

.icon-exit-full-page::before {
	content: "\e94f";
}

.icon-expand::before {
	content: "\e950";
}

.icon-expand2::before {
	content: "\e951";
}

.icon-external::before {
	content: "\e952";
}

.icon-eye-slash::before {
	content: "\e953";
}

.icon-eye::before {
	content: "\e954";
}

.icon-files::before {
	content: "\e955";
}

.icon-filter::before {
	content: "\e956";
}

.icon-focus::before {
	content: "\e957";
}

.icon-folder::before {
	content: "\e958";
}

.icon-fx-circle::before {
	content: "\e959";
}

.icon-fx-dotted::before {
	content: "\e95a";
}

.icon-fx::before {
	content: "\e95b";
}

.icon-generate::before {
	content: "\e95c";
}

.icon-generator::before {
	content: "\e95d";
}

.icon-help-1::before {
	content: "\e95e";
}

.icon-help::before {
	content: "\e95f";
}

.icon-hub::before {
	content: "\e960";
}

.icon-image::before {
	content: "\e961";
}

.icon-import::before {
	content: "\e962";
}

.icon-info-full::before {
	content: "\e963";
}

.icon-info::before {
	content: "\e964";
}

.icon-input::before {
	content: "\e965";
}

.icon-italic::before {
	content: "\e966";
}

.icon-item-description::before {
	content: "\e967";
}

.icon-item-name::before {
	content: "\e968";
}

.icon-item-number::before {
	content: "\e969";
}

.icon-java::before {
	content: "\e96a";
}

.icon-json::before {
	content: "\e96b";
}

.icon-keyboard::before {
	content: "\e96c";
}

.icon-kotlin::before {
	content: "\e96d";
}

.icon-left-trail::before {
	content: "\e96e";
}

.icon-lightning::before {
	content: "\e96f";
}

.icon-link::before {
	content: "\e970";
}

.icon-lock::before {
	content: "\e971";
}

.icon-logout::before {
	content: "\e972";
}

.icon-ltr::before {
	content: "\e973";
}

.icon-main-trail::before {
	content: "\e974";
}

.icon-manage-actions::before {
	content: "\e975";
}

.icon-manage-column::before {
	content: "\e976";
}

.icon-map-center::before {
	content: "\e977";
}

.icon-map-left-2::before {
	content: "\e978";
}

.icon-map-left::before {
	content: "\e979";
}

.icon-map-right-2::before {
	content: "\e97a";
}

.icon-map-right::before {
	content: "\e97b";
}

.icon-map-to-input::before {
	content: "\e97c";
}

.icon-map-to-output::before {
	content: "\e97d";
}

.icon-mapper::before {
	content: "\e97e";
}

.icon-maximize::before {
	content: "\e97f";
}

.icon-method-failed::before {
	content: "\e980";
}

.icon-method-complete::before {
	content: "\e981";
}

.icon-method-completed::before {
	content: "\e982";
}

.icon-method-incomplete::before {
	content: "\e983";
}

.icon-methods::before {
	content: "\e984";
}

.icon-minimize::before {
	content: "\e985";
}

.icon-minus::before {
	content: "\e986";
}

.icon-model::before {
	content: "\e987";
}

.icon-modules::before {
	content: "\e988";
}

.icon-note::before {
	content: "\e989";
}

.icon-notifications::before {
	content: "\e98a";
}

.icon-open-api::before {
	content: "\e98b";
}

.icon-output::before {
	content: "\e98c";
}

.icon-page-down-action::before {
	content: "\e98d";
}

.icon-page-left-action::before {
	content: "\e98e";
}

.icon-page-right-action::before {
	content: "\e98f";
}

.icon-page-up-action::before {
	content: "\e990";
}

.icon-part-select::before {
	content: "\e991";
}

.icon-paste::before {
	content: "\e992";
}

.icon-pdf::before {
	content: "\e993";
}

.icon-play::before {
	content: "\e994";
}

.icon-plus-circle::before {
	content: "\e995";
}

.icon-plus::before {
	content: "\e996";
}

.icon-pool-config::before {
	content: "\e997";
}

.icon-populate-left::before {
	content: "\e998";
}

.icon-powerpoint::before {
	content: "\e999";
}

.icon-profile::before {
	content: "\e99a";
}

.icon-projects::before {
	content: "\e99b";
}

.icon-refresh::before {
	content: "\e99c";
}

.icon-remove-fx::before {
	content: "\e99d";
}

.icon-rest-tag::before {
	content: "\e99e";
}

.icon-retry-policy::before {
	content: "\e99f";
}

.icon-reverse-api::before {
	content: "\e9a0";
}

.icon-review-projects::before {
	content: "\e9a1";
}

.icon-right-trail::before {
	content: "\e9a2";
}

.icon-rocket::before {
	content: "\e9a3";
}

.icon-rtl::before {
	content: "\e9a4";
}

.icon-save::before {
	content: "\e9a5";
}

.icon-screen-asset::before {
	content: "\e9a6";
}

.icon-screen-size::before {
	content: "\e9a7";
}

.icon-screens-on-flow::before {
	content: "\e9a8";
}

.icon-search::before {
	content: "\e9a9";
}

.icon-selection-bottom-right::before {
	content: "\e9aa";
}

.icon-selection-top-left::before {
	content: "\e9ab";
}

.icon-send::before {
	content: "\e9ac";
}

.icon-serverless::before {
	content: "\e9ad";
}

.icon-services::before {
	content: "\e9ae";
}

.icon-set-static-value::before {
	content: "\e9af";
}

.icon-settings::before {
	content: "\e9b0";
}

.icon-share::before {
	content: "\e9b1";
}

.icon-show-source::before {
	content: "\e9b2";
}

.icon-show-target::before {
	content: "\e9b3";
}

.icon-solutions::before {
	content: "\e9b4";
}

.icon-sort::before {
	content: "\e9b5";
}

.icon-source-file::before {
	content: "\e9b6";
}

.icon-spring::before {
	content: "\e9b7";
}

.icon-stack::before {
	content: "\e9b8";
}

.icon-string-text::before {
	content: "\e9b9";
}

.icon-table-action::before {
	content: "\e9ba";
}

.icon-table::before {
	content: "\e9bb";
}

.icon-test-center::before {
	content: "\e9bc";
}

.icon-test-complete::before {
	content: "\e9bd";
}

.icon-test-incomplete::before {
	content: "\e9be";
}

.icon-text-id::before {
	content: "\e9bf";
}

.icon-underline::before {
	content: "\e9c0";
}

.icon-unknown::before {
	content: "\e9c1";
}

.icon-unlock::before {
	content: "\e9c2";
}

.icon-up-down::before {
	content: "\e9c3";
}

.icon-upload::before {
	content: "\e9c4";
}

.icon-user::before {
	content: "\e9c5";
}

.icon-variable::before {
	content: "\e9c6";
}

.icon-view-action::before {
	content: "\e9c7";
}

.icon-wand::before {
	content: "\e9c8";
}

.icon-warning::before {
	content: "\e9c9";
}

.icon-word::before {
	content: "\e9ca";
}

.icon-x-dotted::before {
	content: "\e9cc";
}

.icon-x::before {
	content: "\e9cd";
}
