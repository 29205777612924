$line-heights: (
	'1': 1rem,
	'1-5': 1.5rem,
	'1-75': 1.75rem,
	'1-875': 1.875rem,
	'2-5': 2.5rem,
	100: 100%,
	110: 110%,
	120: 120%,
	130: 130%,
	150: 150%,
	160: 160%,
	170: 170%,
);

/**
 hub-text-lh-1
 hub-text-lh-1-5
 hub-text-lh-1-75
 hub-text-lh-1-875
 hub-text-lh-2-5
 hub-text-lh-100
 hub-text-lh-110
 hub-text-lh-120
 hub-text-lh-130
 hub-text-lh-150
 hub-text-lh-160
 hub-text-lh-170
 */
@each $prop, $abbrev in (line-height: text-lh) {
	@each $size, $length in $line-heights {
		.#{$prefix}-#{$abbrev}-#{$size} { #{$prop}: $length !important; }
	}
}
